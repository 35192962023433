@font-face {
  font-family: 'NotoSansThai-Regular';
  src: url(./assets/font/static/NotoSansThai/NotoSansThai-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'NotoSansThai-Regular';
  src: url(./assets/font/static/NotoSansThai/NotoSansThai-Regular.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'NotoSans-Regular';
  src: url(./assets/font/static/NotoSansENG/NotoSans-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'NotoSans-Regular';
  src: url(./assets/font/static/NotoSansENG/NotoSans-Regular.ttf) format('truetype');
  font-weight: 300;
}
* {
  font-family: NotoSansThai-Regular, NotoSans-Regular !important;
}

html {
  scroll-behavior: smooth;
}

