
.select-btn-container {
    width: 68px;
    height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #bbbbbb;
    border-radius: 9px;
    background: #fff;
    cursor: pointer;
    color: #bbbbbb;
    user-select: none;
}

.select-btn-container.active {
    border: 1px solid #fecc00;
    background: #fecc00;
    color: rgb(48, 48, 48);
}

.select-btn-icon-container {
    width: 18px;
    height: 100%;
    margin-right: 8px;
    display: flex;
    justify-content: end;
    align-items: center;
}
